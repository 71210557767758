import React from 'react'

const NewsletterForm = ({ logo }) => {
  return (
    <div className="newsletter-form">
      <div style={{ textAlign: 'center' }}>
        <img
          src="/the-thinker.png"
          style={{
            width: 'auto',
            height: 100,
            marginTop: 10,
          }}
        />
      </div>
      <h3 style={{ marginTop: 0 }}>
        Get Better at Software Design & Architecture
      </h3>
      <p style={{ textAlign: 'center' }}>
        Stay up to date with my writing. No spam. Unsubscribe any
        time.
      </p>
      <form
        action="https://alexkondov.us2.list-manage.com/subscribe/post?u=fca75767fa83fa3473478b4ca&amp;id=495bb9068f"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
      >
        <div className="newsletter-form-input">
          <input
            type="email"
            name="EMAIL"
            placeholder="Your Email Address"
          />
          <input type="hidden" value="1" name="embed" />
          <input type="submit" value="Subscribe" />
        </div>
      </form>
      {/* <p>
        Not sure?{' '}
        <a
          href="https://codephilosophy.dev"
          style={{ boxShadow: 'inset 0 -0.05em 0 #fff' }}
        >
          View previous newsletters.
        </a>
      </p> */}
    </div>
  )
}

export default NewsletterForm
