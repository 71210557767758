import React from 'react'
import Layout from '../components/Layout'
import Bio from '../components/Bio'
import NewsletterForm from '../components/NewsletterForm'
import { rhythm } from '../utils/typography'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const Me = ({ location, data }) => {
  const articles = data.allMarkdownRemark.edges.map(
    (edge) => edge.node
  )
  return (
    <Layout location={location}>
      <Helmet>
        <title>Alex Kondov - Software Engineer</title>
        <meta
          property="og:image"
          content={`https://alexkondov.com${data.cover.childImageSharp.fluid.src}`}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta
          property="og:title"
          content="Alex Kondov's Personal Blog"
        />
        <meta
          property="og:description"
          content="Thoughts on programming, writing and the things in between."
        />
        <meta property="og:url" content="https://alexkondov.com" />
        <meta
          name="twitter:card"
          content="summary_large_image"
        ></meta>
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <Bio tagline={false} />
      <p style={{ marginTop: rhythm(1) }}>
        I'm a software engineer by day and a writer by night.
      </p>
      <p>
        I've spent the last 10 years building software and writing
        about it. JavaScript and React are my weapons of choice, but
        I've dabbled in many other languages and technologies. From
        the front-end to the back-end, from early stage Sillicon Valey
        startups to big media corps like the Financial Times, I've
        built a little bit of everything
      </p>
      <p>
        I'm sharing everything I know about software through my{' '}
        <Link to="/articles">articles</Link>,{' '}
        <Link to="/books">books</Link> and{' '}
        <a
          target="_blank"
          noopener="true"
          noreferrer="true"
          href="https://codephilosophy.dev"
        >
          newsletter.
        </a>
      </p>
      <p>
        When I'm not coding, I'm either lifting, playing Magic: The
        Gathering, or{' '}
        <a target="_blank" href="https://brokenstatuesbook.com">
          writing fiction
        </a>
        .
      </p>
      <h2 className="section-title">Recently Published</h2>
      {articles.map((article, index) => (
        <Link to={article.fields.slug}>
          <div
            key={article.fields.slug}
            className="article-list-item"
          >
            <h3 className="article-list-title">
              {article.frontmatter.title}
            </h3>
            <small>{article.frontmatter.date}</small>
            <div className="divider"></div>
            <p
              dangerouslySetInnerHTML={{ __html: article.excerpt }}
            />
          </div>
        </Link>
      ))}
      {/* <h2 className="section-title">Books</h2>
      <a href="https://taoofnode.com">
        <div className="book">
          <img
            style={{ borderRadius: 3 }}
            src={data.secondBookCover.childImageSharp.fluid.src}
          />
          <div>
            <h2 style={{ marginTop: 10, fontWeight: 400 }}>
              Tao of Node
            </h2>
            <div className="divider"></div>
            <p>
              Learn how to build better Node.js applications. A
              collection of best practices about architecture,
              tooling, performance and testing.
            </p>
          </div>
        </div>
      </a>
      <a href="https://taoofreact.com">
        <div className="book">
          <img
            style={{ borderRadius: 3 }}
            src={data.bookCover.childImageSharp.fluid.src}
          />
          <div>
            <h2 style={{ marginTop: 10, fontWeight: 400 }}>
              Tao of React
            </h2>
            <div className="divider"></div>
            <p>
              Learn proven practices about React application
              architecture, component design, testing and performance.
            </p>
          </div>
        </div>
      </a> */}
      <NewsletterForm />
    </Layout>
  )
}

export default Me

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    bookCover: file(
      absolutePath: { regex: "/tao-of-react-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    secondBookCover: file(
      absolutePath: { regex: "/tao-of-node-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { nin: "fiction" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
